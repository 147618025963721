export const validateEmail = (email: string): string => {
	// simplest thing that looks like an email address
	const re = /^[^@\s]+@[^@\s]+\.[^@\s]+$/i
	if (!email.trim()) {
		return 'Email address is required'
	}
	if (!re.test(String(email).toLowerCase())) {
		return 'Please enter a valid email address'
	}
	return '' // Return an empty string if there are no errors (i.e., the email is valid)
}

export const validatePassword = (password: string) => {
	const errors = []

	if (password.length < 8) {
		errors.push('be at least 8 characters long')
	}
	if (password.length > 128) {
		errors.push('be less than 128 characters long')
	}
	if (!/[A-Z]/.test(password)) {
		errors.push('contain at least one uppercase letter')
	}
	if (!/[a-z]/.test(password)) {
		errors.push('contain at least one lowercase letter')
	}
	if (!/[0-9]/.test(password)) {
		errors.push('contain at least one number')
	}
	// if (!/[\!\@\#\$\%\^\&\*]/.test(password)) {
	// 	errors.push('contain at least one special character (!@#$%^&*)')
	// }
	// Additional checks can be added here.
	if (errors.length > 0) {
		return 'Password must be at least 8 characters, and contain at least one uppercase letter, one lowercase letter, and one number'
	} else return ''
}
